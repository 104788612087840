// App.tsx
import { useState } from "react";
import { lazy, Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { tokenState } from "./state";
import { parseJSON } from "./utils/util";
import analytics from "./firebase";
import { setUserId } from "firebase/analytics";
import { isMarketOnlyUser } from "utils/marketUtil";
import PortalRoot from "component/PortalRoot";
import OutdatedReloader from "component/OutdatedReloader";
import PageTracker from "component/PageTracker";
import CheckCI from "./component/CheckCI";
import IEAlert from "./component/ie_alert";

// CSS imports
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Constants
export const CLASS_ROUTE = "class";
export const EDU_ROUTE = "edu";

// Lazy loaded components
const Home = lazy(() => import("./page/Home"));
const Login = lazy(() => import("./page/user/Login"));
const Logout = lazy(() => import("./page/user/Logout"));
const Verify = lazy(() => import("./page/user/Verify"));
const Auth = lazy(() => import("./page/user/Auth"));
const AuthChicruitingCallback = lazy(() => import("./page/user/AuthChicruitingCallback"));
const PhoneVerify = lazy(() => import("./page/user/PhoneVerify"));
const FindId = lazy(() => import("./page/user/FindId"));
const FindPwd = lazy(() => import("./page/user/FindPwd"));
const RequestSignup = lazy(() => import("./page/user/RequestSignup"));
const SyncAccount = lazy(() => import("./page/user/SyncAccount"));

const BusinessInfo = lazy(() => import("./page/footer/BusinessInfo"));
const Service = lazy(() => import("./page/footer/Service"));
const Privacy = lazy(() => import("./page/footer/Privacy"));

const Board = lazy(() => import("./page/board/Board"));
const Management = lazy(() => import("./page/management/Management"));
const PublicManagement = lazy(() =>
  import("./page/management/Management").then((module) => ({ default: module.PublicManagement })),
);
const Seminar = lazy(() => import("./page/management/seminar/Seminar"));
const SeminarWeeklyRoute = lazy(() =>
  import("./page/management/seminar/Seminar").then((module) => ({
    default: module.SeminarWeeklyRoute,
  })),
);

const TongTest = lazy(() => import("./page/tongtest/TongTest"));
const Festival = lazy(() => import("./page/festival/Festival"));
const Qna = lazy(() => import("./page/qna/Qna"));
const Forum = lazy(() => import("./page/forum/Forum"));
const Class = lazy(() => import("./page/class/Class"));
const KlassRoute = lazy(() => import("page/mordern_class/KlassRoute"));
const Recruit = lazy(() => import("./page/recruit/Recruit"));
const Chicruiting = lazy(() => import("page/chicruiting/Chicruiting"));
const UnloginChicruiting = lazy(() => import("page/chicruiting/UnloginChicruiting"));
const Contents = lazy(() => import("./page/contents/Contents"));
const SearchPage = lazy(() => import("page/search/SearchPage"));
const ProfilePage = lazy(() => import("page/profile/ProfilePage"));
const MoredenAi = lazy(() => import("page/ai/MoredenAi"));
const ExpoRoute = lazy(() => import("./component/expo/ExpoRoute"));
const MyPageRoute = lazy(() => import("./page/mypage/MyPageRoute"));
const SignupRoute = lazy(() => import("./page/signup/SignupRoute"));

const LazyRoute = ({ children }) => <Suspense fallback={null}>{children}</Suspense>;

const MarketOnlyRoutes = () => (
  <>
    <Routes>
      <Route element={<PageTracker />}>
        <Route
          path="/footer/business/*"
          element={
            <LazyRoute>
              <BusinessInfo />
            </LazyRoute>
          }
        />
        <Route
          path="/footer/service/*"
          element={
            <LazyRoute>
              <Service />
            </LazyRoute>
          }
        />
        <Route
          path="/footer/privacy/*"
          element={
            <LazyRoute>
              <Privacy />
            </LazyRoute>
          }
        />
        <Route
          path="/login/*"
          element={
            <LazyRoute>
              <Login />
            </LazyRoute>
          }
        />
        <Route
          path="/logout/*"
          element={
            <LazyRoute>
              <Logout />
            </LazyRoute>
          }
        />
        <Route
          path="/auth/*"
          element={
            <LazyRoute>
              <Auth />
            </LazyRoute>
          }
        />
        <Route
          path="/"
          element={
            <LazyRoute>
              <Home />
            </LazyRoute>
          }
        />
        <Route
          path="*"
          element={
            <LazyRoute>
              <RequestSignup />
            </LazyRoute>
          }
        />
      </Route>
    </Routes>
    <PortalRoot />
  </>
);

const CommunityRoutes = () => (
  <>
    <Routes>
      <Route element={<OutdatedReloader />}>
        <Route element={<PageTracker />}>
          <Route element={<CheckCI />}>
            <Route
              path="/"
              element={
                <LazyRoute>
                  <Home />
                </LazyRoute>
              }
            />
            <Route
              path="/ai/*"
              element={
                <LazyRoute>
                  <MoredenAi />
                </LazyRoute>
              }
            />
            <Route
              path="/recruit/*"
              element={
                <LazyRoute>
                  <Recruit />
                </LazyRoute>
              }
            />
            <Route
              path="/chicruiting/*"
              element={
                <LazyRoute>
                  <Chicruiting />
                </LazyRoute>
              }
            />
            <Route
              path="/syncAccount/*"
              element={
                <LazyRoute>
                  <SyncAccount />
                </LazyRoute>
              }
            />
            <Route
              path="/festival/*"
              element={
                <LazyRoute>
                  <Festival />
                </LazyRoute>
              }
            />
            <Route
              path="/expo/*"
              element={
                <LazyRoute>
                  <ExpoRoute />
                </LazyRoute>
              }
            />
            <Route
              path="/post/*"
              element={
                <LazyRoute>
                  <Board />
                </LazyRoute>
              }
            />
            <Route
              path="/tongtest/*"
              element={
                <LazyRoute>
                  <TongTest />
                </LazyRoute>
              }
            />
            <Route
              path={`/${EDU_ROUTE}/*`}
              element={
                <LazyRoute>
                  <Class />
                </LazyRoute>
              }
            />
            <Route
              path={`/${CLASS_ROUTE}/*`}
              element={
                <LazyRoute>
                  <KlassRoute />
                </LazyRoute>
              }
            />
            <Route
              path="/search/*"
              element={
                <LazyRoute>
                  <SearchPage />
                </LazyRoute>
              }
            />
            <Route
              path="/contents/*"
              element={
                <LazyRoute>
                  <Contents />
                </LazyRoute>
              }
            />
            <Route
              path="/qna/*"
              element={
                <LazyRoute>
                  <Qna />
                </LazyRoute>
              }
            />
            <Route
              path="/forum/*"
              element={
                <LazyRoute>
                  <Forum />
                </LazyRoute>
              }
            />
            <Route
              path="/management/seminar/*"
              element={
                <LazyRoute>
                  <Seminar />
                </LazyRoute>
              }
            />
            <Route
              path="/management/seminar_weekly/*"
              element={
                <LazyRoute>
                  <SeminarWeeklyRoute />
                </LazyRoute>
              }
            />
            <Route
              path="/auth/chicruiting/callback"
              element={
                <LazyRoute>
                  <AuthChicruitingCallback />
                </LazyRoute>
              }
            />
            <Route
              path="/management/*"
              element={
                <LazyRoute>
                  <Management />
                </LazyRoute>
              }
            />
            <Route
              path="/mypage/*"
              element={
                <LazyRoute>
                  <MyPageRoute />
                </LazyRoute>
              }
            />
            <Route
              path="/footer/business"
              element={
                <LazyRoute>
                  <BusinessInfo />
                </LazyRoute>
              }
            />
            <Route
              path="/footer/service/*"
              element={
                <LazyRoute>
                  <Service />
                </LazyRoute>
              }
            />
            <Route
              path="/footer/privacy/*"
              element={
                <LazyRoute>
                  <Privacy />
                </LazyRoute>
              }
            />
            <Route
              path="/profile/:uid"
              element={
                <LazyRoute>
                  <ProfilePage />
                </LazyRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
          <Route
            path="/logout/*"
            element={
              <LazyRoute>
                <Logout />
              </LazyRoute>
            }
          />
        </Route>
      </Route>
    </Routes>
    <PortalRoot />
  </>
);

const PublicRoutes = () => (
  <>
    <Routes>
      <Route element={<PageTracker />}>
        <Route
          path={`/${EDU_ROUTE}/*`}
          element={
            <LazyRoute>
              <Class />
            </LazyRoute>
          }
        />
        <Route
          path={`/${CLASS_ROUTE}/*`}
          element={
            <LazyRoute>
              <KlassRoute />
            </LazyRoute>
          }
        />
        <Route
          path="/recruit/*"
          element={
            <LazyRoute>
              <Recruit />
            </LazyRoute>
          }
        />
        <Route
          path="/chicruiting/*"
          element={
            <LazyRoute>
              <UnloginChicruiting />
            </LazyRoute>
          }
        />
        <Route
          path="/contents/*"
          element={
            <LazyRoute>
              <Contents />
            </LazyRoute>
          }
        />
        <Route
          path="/festival/*"
          element={
            <LazyRoute>
              <Festival />
            </LazyRoute>
          }
        />
        <Route
          path="/expo/*"
          element={
            <LazyRoute>
              <ExpoRoute />
            </LazyRoute>
          }
        />
        <Route
          path="/tongtest/*"
          element={
            <LazyRoute>
              <TongTest />
            </LazyRoute>
          }
        />
        <Route
          path="/management/seminar/*"
          element={
            <LazyRoute>
              <Seminar />
            </LazyRoute>
          }
        />
        <Route
          path="/management/seminar_weekly/*"
          element={
            <LazyRoute>
              <SeminarWeeklyRoute />
            </LazyRoute>
          }
        />
        <Route
          path="/management/*"
          element={
            <LazyRoute>
              <PublicManagement />
            </LazyRoute>
          }
        />
        <Route
          path="/auth/chicruiting/callback"
          element={
            <LazyRoute>
              <AuthChicruitingCallback />
            </LazyRoute>
          }
        />
        <Route
          path="/footer/business/*"
          element={
            <LazyRoute>
              <BusinessInfo />
            </LazyRoute>
          }
        />
        <Route
          path="/footer/service/*"
          element={
            <LazyRoute>
              <Service />
            </LazyRoute>
          }
        />
        <Route
          path="/footer/privacy/*"
          element={
            <LazyRoute>
              <Privacy />
            </LazyRoute>
          }
        />
        <Route
          path="/signup/*"
          element={
            <LazyRoute>
              <SignupRoute />
            </LazyRoute>
          }
        />
        <Route
          path="/phoneVerify/*"
          element={
            <LazyRoute>
              <PhoneVerify />
            </LazyRoute>
          }
        />
        <Route
          path="/verifyPending/*"
          element={
            <LazyRoute>
              <Verify />
            </LazyRoute>
          }
        />
        <Route
          path="/findid/*"
          element={
            <LazyRoute>
              <FindId />
            </LazyRoute>
          }
        />
        <Route
          path="/findpw/*"
          element={
            <LazyRoute>
              <FindPwd />
            </LazyRoute>
          }
        />
        <Route
          path="/syncAccount/*"
          element={
            <LazyRoute>
              <SyncAccount />
            </LazyRoute>
          }
        />
        <Route
          path="/login/*"
          element={
            <LazyRoute>
              <Login />
            </LazyRoute>
          }
        />
        <Route
          path="/logout/*"
          element={
            <LazyRoute>
              <Logout />
            </LazyRoute>
          }
        />
        <Route
          path="/auth/*"
          element={
            <LazyRoute>
              <Auth />
            </LazyRoute>
          }
        />
        <Route
          path="/"
          element={
            <LazyRoute>
              <Home />
            </LazyRoute>
          }
        />
        <Route
          path="*"
          element={
            <LazyRoute>
              <RequestSignup />
            </LazyRoute>
          }
        />
      </Route>
    </Routes>
    <PortalRoot />
  </>
);

const App = () => {
  const location = useLocation();
  const token = useRecoilValue(tokenState);
  const [isClosed, setIsClosed] = useState(parseJSON(sessionStorage.getItem("ie_closed")));
  const rcode = new URLSearchParams(location.search).get("rcode");
  const isIE = navigator.userAgent.indexOf("MSIE") !== -1 || !!document.documentMode === true;

  analytics && token && token.uid && setUserId(analytics, token.uid);

  if (rcode && !localStorage.getItem("rcode")) {
    localStorage.setItem("rcode", rcode);
  }

  if (isIE && !isClosed) {
    return <IEAlert continueIE={setIsClosed} />;
  }

  if (token && token?.user_type && isMarketOnlyUser(token.user_type)) {
    return <MarketOnlyRoutes />;
  }

  if (token && token.uid && token?.user_type) {
    return <CommunityRoutes />;
  }

  return <PublicRoutes />;
};

export default App;
