import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { isMarketOnlyUser } from "utils/marketUtil";
import { api } from "utils/util";

export interface MypageInfo {
  id: string;
  nickname: string;
  name: string;
  email: string;
  gender: string;
  phone_number: string;
  birthday: string;
  user_type: string;
  recommend_code: string;
  is_owner: boolean;
  is_unified: boolean;
  has_verified_phone: boolean;
  promotion: Promotion;
  user_level: number;
  board_cnt: number;
  comment_cnt: number;
  point: PointInfo;
  user_rank: UserRank;
  hospital: Hospital;
  subscription_cnt: number;
}

export interface Promotion {
  title: string;
  detail: boolean;
  sum: number;
  text: string;
  article: number;
  comment: number;
  remain_article: number;
  remain_comment: number;
  article_convert: number;
  comment_convert: number;
  recommend: number;
  recommend_ticket: string;
}

export interface PointInfo {
  unick: string;
  total_point: number;
  board_point: number;
  forum_point: number;
  qna_point: number;
  qna_admin_selected: number;
  qna_writer_selected: number;
  forum_admin_selected: number;
  forum_expert_selected: number;
}

export interface UserRank {
  uid: number;
  nickname: string;
  total_point: number;
  board_point: number;
  qna_point: number;
  forum_point: number;
  qna_admin_selected: number;
  qna_writer_selected: number;
  forum_admin_selected: number;
  forum_expert_selected: number;
  board_rank: number;
  forum_rank: number;
  qna_rank: number;
}

export interface Hospital {
  id: number;
  name: string;
  type: string;
  callNumber: string | null;
  adDisagreementDate: string | null;
  adAgreement: string | null;
  address: string;
  detail_address: string;
  latitude: number;
  longitude: number;
  sourceType: string;
  status: string;
  postal_code: string;
  owner: string;
  email: string;
  businessRegistrationNumber: string;
  businessRegistrationCertificateImagePath: string;
  healthInsuranceIdentificationNumber: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export const useUserInfo = () => {
  const token = useRecoilValue(tokenState);
  return useQuery(
    ["/mypage/info", token?.uid],
    () => api.get<MypageInfo>("/mypage/info").then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: 15 * 60 * 1000,
      enabled: !!(token && token.user_type && !isMarketOnlyUser(token.user_type)),
    },
  );
};

export const useUserGroupCode = () => {
  const { data: myInfo, isLoading: myInfoLoading } = useUserInfo();

  const userGroupCode = getUserGroupCode(myInfo);

  return { data: userGroupCode, isLoading: myInfoLoading };
};

const getUserGroupCode = (myInfo: MypageInfo | undefined) => {
  const userGroupCodeList = [0, 0, 0];

  if (myInfo) {
    if (myInfo.gender === "M") {
      userGroupCodeList[0] = 1;
    } else if (myInfo.gender === "F") {
      userGroupCodeList[0] = 2;
    }

    if (myInfo.user_type === "doctor") {
      userGroupCodeList[1] = 1;
    } else if (myInfo.user_type === "student") {
      userGroupCodeList[1] = 2;
    }

    if (myInfo.is_owner) {
      userGroupCodeList[2] = 1;
    } else if (!myInfo.is_owner) {
      userGroupCodeList[2] = 2;
    }
  }

  return userGroupCodeList.join("");
};

interface NoticeListResponse {
  notice: {
    bid: number;
    deleted: boolean;
    reg_dttm: string;
    title: string;
  }[];
  page: number;
  pages: number;
}

export const useNoticeList = ({ page }: { page: string | undefined }) => {
  return useQuery(["mypageNoticeList", page], () =>
    api
      .get<NoticeListResponse>(`/mypage/noticeList`, {
        params: {
          page,
          per_page: 10,
        },
      })
      .then((res) => res.data),
  );
};

interface NoticeResponse {
  images: {
    link: string;
  }[];
  notice: {
    bid: number;
    content: string;
    reg_dttm: string;
    title: string;
  };
  scraped: boolean;
}

export const useNotice = (bid: string | undefined) => {
  return useQuery(["mypageNotice", bid], () =>
    api.get<NoticeResponse>(`/mypage/notice/${bid}`).then((res) => res.data),
  );
};

interface MyPointListResponse {
  items: {
    created_at: string;
    desc: string;
    level: number;
    link: string;
    point: number;
    rid: number;
    unick: string;
  }[];
  page: number;
  pages: number;
}

export const useMyPointList = ({
  type,
  page,
}: {
  type: string | undefined;
  page: string | undefined;
}) =>
  useQuery(["mypagePointList", type, page], () =>
    api
      .get<MyPointListResponse>(`/mypage/pointList/${type}`, {
        params: {
          page,
        },
      })
      .then((res) => res.data),
  );

interface MyAlarmListResponse {
  noti: {
    nid: number;
    content: string;
    link: string;
    noti_status: "read" | "received";
    noti_type: "comment" | "article" | "like" | "badge" | "admin_select" | "writer_select";
    reg_dttm: string;
  }[];
  page: number;
  pages: number;
}

export const useMyAlarmList = ({ page }: { page: string | undefined }) => {
  return useQuery(["mypageAlarmList", page], () =>
    api
      .get<MyAlarmListResponse>(`/user/notification`, {
        params: {
          page,
          per_page: 10,
        },
      })
      .then((res) => res.data),
  );
};
