import { PuffLoader } from "react-spinners";

const Loading = ({ small = false }: { small?: boolean }) => {
  if (small) {
    <div className="w-[60px] h-[60px]">
      <PuffLoader color="#4a25aa" />
    </div>;
  }
  return (
    <div className="loading_box">
      <div className="loading">
        <PuffLoader color="#4a25aa" />
      </div>
    </div>
  );
};

export default Loading;
